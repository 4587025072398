import { Injectable, signal } from '@angular/core';
import { ILoaderStore, LoaderState } from './loader.types';

@Injectable({
  providedIn: 'root',
})
export class LoaderStore implements ILoaderStore {
  private loaderState = signal<LoaderState>({ show: false, title: '' });

  public show(title = '') {
    this.loaderState.set({ show: true, title });
  }

  public hide() {
    this.loaderState.set({ show: false, title: '' });
  }

  public getState() {
    return this.loaderState.asReadonly();
  }
}
